.background{
    background-color: #e9e8e7;
}
.name{
    margin-left: 3rem;
    margin-top: 14rem;
    
}
.typo{
    margin-top: 3rem;
    color: black;

}
.iam>span{
    color: #e53232;
    font-size: 31px;
    font-family: fantasy;
    text-shadow: 3px 2px 3px #541e54;
    bottom: 3rem;
    
}
.typing{
    margin-top: 1rem;
    text-shadow: 3px 1px 5px #bdbdac;
    margin-bottom: 6rem;
}
.hi{
    color: black;
    font-family: fantasy;
    font-weight: 100;
    font-size: 34px;
    text-shadow: 1px 0px 1px #9d5f5f;
}
.hello{
    color: black;
    background-color: brown;
}
.buttons>:nth-child(2){
    background-color: #f0b016;
    border: 1px solid #f0b016;
    margin-left: 3rem;
}
.buttons>:nth-child(2):hover{
    background-color: transparent;
    color:#f0b016 ;
}
.buttons>:nth-child(1):hover{
    color: blue;
    background-color: transparent;
}

@media screen and (max-width:858px) {

    .typo{
    margin-top: 2rem;
}
.typing{
    margin-top: 1rem;
    text-shadow: 3px 1px 5px #bdbdac;
    margin-bottom: 6rem;
}
.name{
    margin-left: 3rem;
    margin-top: 8rem;
    
}


}
@media screen and (max-width:493px) {

    .typo{
    margin-top: 2rem;
}
.typing{
    margin-top: 1rem;
    text-shadow: 3px 1px 5px #bdbdac;
    margin-bottom: 6rem;
}
.name{
    margin-left: 3rem;
    margin-top: 4rem;
    
}


}
